import React from "react"
import { css } from "@emotion/core"
import Mleczarnia from "../../static/ref/mleczarnia.png"
import Ofop from "../../static/ref/ofop.png"
import Organic from "../../static/ref/organic_farma_zdrowia.png"
import Pcpm from "../../static/ref/pcpm.png"
import Prasowy from "../../static/ref/prasowy.png"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Testimonials = () => {
  return (
    <>
      <div
        css={css`
          background-color: var(--light-gray);
          background-size: cover;
          padding: 5px 0 20px 0;
          text-align: center;
          font-size: 2rem;
          @media (max-width: 850px) {
            font-size: 1.2rem;
          }
          h4 {
            font-weight: 300;
          }
          span {
            color: var(--primary-default);
            font-weight: 400;
          }
        `}
      >
        <div className="wrapper">
          <h4>
            Mamy ponad <span>250</span> zadowolonych klientów,
            <br /> stale współpracujemy m.in. z:
          </h4>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              @media (max-width: 850px) {
                justify-content: center;
              }
              flex-wrap: wrap;
              img {
                flex-basis: 15%;
                max-width: 18%;

                @media (max-width: 850px) {
                  flex-basis: 35%;
                  max-width: 30%;
                }
              }
            `}
          >
            <img src={Mleczarnia} alt="Mleczarnia Jerozolimska" />
            <img src={Organic} alt="Organic Farma Zdrowia" />
            <img src={Pcpm} alt="Polskie Centrum Pomocy Międzynarodowej" />
            <img
              src={Ofop}
              alt="Ogólnopolska Federacja Organizacji Pozarządowych"
            />
            <img src={Prasowy} alt="Prasowy Bar Mleczny" />
          </div>
          <p
            css={css`
              font-size: 1.2rem;
              font-weight: 300;
            `}
          >
            Więcej informacji o naszych klientach znajdziesz w zakładce{" "}
            <AniLink
              paintDrip
              duration={0.7}
              hex="#7a2c7d"
              css={css`
                color: var(--primary-default);
                text-decoration: none;
                font-weight: 400;
                border-bottom: 1px solid;
              `}
              to="/referencje"
            >
              Referencje
            </AniLink>
          </p>
        </div>
      </div>
    </>
  )
}

export default Testimonials
