import React from "react"
import Layout from "../components/layout"
import HomeHero from "../components/homeHero"
import Services from "../components/services"
import Testimonials from "../components/testimonials"

export default function Home() {
  return (
    <Layout>
      <HomeHero />
      <Services />
      <Testimonials />
    </Layout>
  )
}
