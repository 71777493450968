import React, { useState, useEffect } from "react"
import { css } from "@emotion/core"
import backgroundImage from "../../static/back1.jpg"
import ReactTooltip from "react-tooltip"
import { CgArrowDownR } from "react-icons/cg"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const wordList = [
  "wizytówek",
  "kasetonu",
  "strony www",
  "aplikacji",
  "rollupu",
  "logotypu",
  "liter 3D",
  "ulotek",
  "e-sklepu",
  "gadżetów",
]

const days = () => {
  const startTime = 1264519667463
  let currentTime = new Date()
  currentTime = currentTime.getTime()
  let daysPassed = (currentTime - startTime) / 1000 / 60 / 60 / 24
  return `A dokładnie od ${Math.round(daysPassed)} dni`
}

const HomeHero = () => {
  const [word, setWord] = useState(wordList[0])

  useEffect(() => {
    const wordChange = setTimeout(() => {
      let i = wordList.indexOf(word)
      if (i === wordList.length - 1) {
        i = -1
      }
      setWord(wordList[i + 1])
    }, 1200)
    return () => {
      clearTimeout(wordChange)
    }
  }, [word])

  return (
    <>
      <div
        css={css`
          background-image: url(${backgroundImage});
          background-position: left top;
          background-size: cover;
          margin-top: -100px;
          height: 100vh;
          z-index: -1;
          color: white;
          display: flex;
          align-content: center;
          @media (max-width: 850px) {
            text-align: center;
          }
        `}
      >
        <section className="wrapper">
          <p
            css={css`
              font-size: 3rem;
              font-weight: 900;
              margin-bottom: 35px;
              @media (max-width: 850px) {
                font-size: 1.4rem;
              }
            `}
          >
            Jeśli potrzebujesz{" "}
            <span
              css={css`
                color: var(--secondary-color);
                display: inline-block;
                transition: all 0.3s ease-in-out;
                /* text-shadow: 2px 1px 0px #7a2c7d; */
              `}
            >
              {word}
            </span>
            <br />
            to chętnie Ci pomożemy. <br />
            Zadzwoń!
            <br />
          </p>

          <a
            href="tel:+48508115501"
            css={css`
              padding: 12px 25px;
              border: 1px solid var(--primary-default);
              color: white;
              background-color: var(--primary-default);
              text-decoration: none;
              font-size: 1.3rem;
              font-weight: 900;
              box-shadow: 0px 0px 3px var(--primary-dark);
              border-radius: 4px;
              transition: all 0.3s ease-in-out;
              :hover {
                background-color: var(--primary-dark);
                box-shadow: 0px 0px 15px var(--primary-dark);
                border: 1px solid var(--primary-default);
              }
            `}
          >
            tel. 508 115 501
          </a>
          <p
            css={css`
              margin-top: 2rem;
              font-size: 1.5rem;
              font-weight: 300;
              line-height: 1.3;
              @media (max-width: 850px) {
                font-size: 1rem;
              }
              span {
                border-bottom: 1px dashed var(--secondary-color);
                font-weight: 400;
                cursor: default;
              }
            `}
          >
            Od ponad{" "}
            <span data-tip data-for="happyFace">
              10 lat
            </span>{" "}
            skutecznie dostarczamy
            <br /> produkty reklamowe naszym klientom w{" "}
            <span
              data-for="city"
              data-tip="Również w okolicach, a czasami w całym kraju"
            >
              Warszawie
            </span>
            .
          </p>
        </section>
      </div>
      <ReactTooltip id="happyFace" effect="solid">
        <span>{days()}</span>
      </ReactTooltip>
      <ReactTooltip id="city" effect="solid" />
      <AnchorLink to="/#services">
        <div
          css={css`
            margin-top: -130px;
            color: var(--secondary-color);
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: center;
            font-size: 3.5rem;
            opacity: 0.5;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            :hover {
              opacity: 1;
              transform: translateY(5px);
            }
          `}
        >
          <CgArrowDownR />
        </div>
      </AnchorLink>
    </>
  )
}

export default HomeHero
