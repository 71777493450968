import React from "react"
import { css } from "@emotion/core"
import icon1 from "../../static/analiza_potrzeb.svg"
import icon2 from "../../static/projekt_i_wykonanie.svg"
import background from "../../static/services_back.svg"
import icon3 from "../../static/oferta_reklama_zewnetrzna.svg"
import icon4 from "../../static/oferta_poligrafia.svg"
import icon5 from "../../static/oferta_strony_i_sklepy.svg"
import icon6 from "../../static/oferta_kreacja.svg"
import arrow from "../../static/arrow.svg"

const services = () => {
  return (
    <>
      <div
        css={css`
          background-color: var(--primary-default);
          min-height: 100px;
          margin-bottom: 0px;
        `}
      >
        <section
          className="wrapper"
          css={css`
            text-align: center;
            color: white;
            padding-top: 20px;
            padding-bottom: 10px;

            h1 {
              font-weight: 500;
              font-size: 1.9rem;
              margin-bottom: 40px;
              @media (max-width: 850px) {
                font-size: 1.6rem;
              }
            }

            span {
              font-weight: 00;
              font-size: 1.6rem;
              font-weight: 200;
              @media (max-width: 850px) {
                font-size: 1.3rem;
              }
            }
          `}
        >
          <h1 id="services">
            Oferujemy pełen zakres usług: <br />
            <span>
              reklamowych, graficznych, drukarskich oraz internetowych.
            </span>
          </h1>
          <div
            css={css`
              display: flex;
              justify-content: space-around;
              align-items: top;
              font-size: 1rem;

              h3 {
                font-weight: 500;
                font-size: 1.5rem;
                padding-bottom: 5px;
                margin-bottom: 0px;
                @media (max-width: 850px) {
                  font-size: 1.2rem;
                }
              }
              p {
                margin-top: 5px;
                font-weight: 300;
              }
              .div1 {
                flex-basis: 45%;
              }
              .div2 {
                flex-basis: 15%;
                display: flex;
                @media (max-width: 850px) {
                  display: none;
                }
              }
              .icon {
                height: 100px;
              }
              .arrow {
                width: 100px;
              }
            `}
          >
            <div className="div1">
              <img className="icon" src={icon1} alt="Analiza potrzeb" />
              <h3>Analiza potrzeb</h3>
              <p>
                Korzystając z naszego doświadczenia pomożemy Ci w wyborze
                optymalnego produktu.
              </p>
            </div>
            <div className="div2">
              <img className="arrow" src={arrow} alt="arrow" />
            </div>
            <div className="div1">
              <img className="icon" src={icon2} alt="Projekt i wykonanie" />
              <h3>Projekt i wykonanie</h3>
              <p>
                Zaprojektujemy, a następnie kompleksowo zrealizujemy usługę
                razem z drukiem / wdrożeniem.
              </p>
            </div>
          </div>
        </section>
      </div>
      <div
        css={css`
          background: url(${background}) no-repeat;
          background-color: white;
          background-position: center top;
          min-height: 100px;
          padding-top: 70px;
          @media (max-width: 850px) {
            padding-top: 20px;
          }
          h3 {
            text-align: center;
            font-size: 2rem;
          }
        `}
      >
        <div className="wrapper">
          <h3>Nasze specjalizacje:</h3>
          <div
            css={css`
              display: flex;
              text-align: center;
              flex-wrap: wrap;
              justify-content: space-between;
              > article {
                flex-basis: 46%;
                margin-bottom: 20px;
                @media (max-width: 850px) {
                  flex-basis: 100%;
                }
              }
              p {
                text-align: justify;
                @media (max-width: 850px) {
                  text-align: justify;
                }
              }
              img {
                height: 70px;
              }
            `}
          >
            <article>
              <img src={icon3} alt="Reklama zewnętrzna" />
              <h2>Reklama zewnętrzna</h2>
              <p>
                Jeśli potrzebujesz kasetonu lub liter przestrzennych 3D to
                dobrze trafiłeś! Mamy na koncie ponad 100 realizacji tej formy
                reklamy zarówno w lokalach przy ulicy jak i w galeriach
                handlowych. Wykonujemy różne ich rodzaje w zależności od
                potrzeb, m.in. z dibondu, napinane oraz z pleksy.{" "}
              </p>
              <p>
                W ramach reklamy zewnętrznej oferujemy również m.in. bannery,
                systemy wystawiennicze, rollupy, oklejanie samochodów i witryn
                sklepowych oraz dziesiątki innych produktów.{" "}
              </p>
            </article>
            <article>
              <img src={icon4} alt="Poligrafia" />
              <h2>Poligrafia</h2>
              <p>
                Oprócz standardowej oferty każdej agencji reklamowej jak np.
                wizytówki, teczki, plakaty i ulotki drukowane cyfrowo lub
                offsetowo, oferujemy również solventowy druk wielkoformatowy i
                uv na różnych materiałach. Drukujemy książki i inne publikacje,
                folie, etykiety, gadżety itp. Realizujemy również zlecenia i
                projekty nietypowe jak np. ulotki sztancowane.
              </p>
              <p>
                Każdy produkt dowozimy w Warszawie i okolicach lub wysyłamy w
                ustalone miejsce dotrzymując terminów.
              </p>
            </article>
            <article>
              <img src={icon5} alt="Strony i sklepy www" />
              <h2>Strony i sklepy www</h2>
              <p>
                Tworzymy responsywne i nowoczesne strony oraz sklepy internetowe
                korzystajac z wielu rozwiązań. Od stron statycznych, przez
                popularnego WordPress'a, aż po rozwiązania dostosowane pod
                konkretne potrzeby klienta. Zajmujemy się również pisaniem
                aplikacji webowych w oparciu o bibliotekę React (Gatsby.js,
                Next.js).{" "}
              </p>
              <p>
                Współpracujemy z wiodącymi na rynku serwisami hostingowymi
                zapewniając klientom stabilne i ciągłe działanie stron i
                aplikacji.
              </p>
            </article>
            <article>
              <img src={icon6} alt="Kreacja" />
              <h2>Kreacja</h2>
              <p>
                Identyfikujemy wizualnie nowe firmy tworząc od podstaw logotypy
                i księgi znaku. Pomożemy również w rebrandingu i odświeżeniu
                logo obecnie działającej firmy chcącej zmienić swój wizerunek.
              </p>
              <p>
                Działamy również jako tradycyjne studio graficzne projektując
                wszelkiego rodzaju wydruki, ulotki, plakaty, foldery itp.
                Przygotowujemy do druku również gotowe materiały od klientów
                szukających samej usługi druku.
              </p>
            </article>
          </div>
        </div>
      </div>
    </>
  )
}

export default services
